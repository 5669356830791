import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeGA } from './utils/google-analytics-util';

const LazyIndex = React.lazy(() => import('./pages/index'));
const LazyPrice = React.lazy(() => import('./pages/price'));
const LazyWorks = React.lazy(() => import('./pages/works'));
const LazyCompany = React.lazy(() => import('./pages/company'));
const LazyContact = React.lazy(() => import('./pages/contact'));
const LazyComplete = React.lazy(() => import('./pages/complete'));
const LazyLp = React.lazy(() => import('./pages/lp'));
const LazyLoading = React.lazy(() => import('./pages/loading'));

function App() {
  useEffect(() => {
    // GoogleAnalytics gtag.js読み込み
    initializeGA();
  }, []);

  return (
    <Router>
      <Suspense fallback={<LazyLoading />}>
        <Routes>
          <Route path="/" element={<LazyIndex />} />
          <Route path="/price" element={<LazyPrice />} />
          <Route path="/works" element={<LazyWorks />} />
          <Route path="/company" element={<LazyCompany />} />
          <Route path="/contact" element={<LazyContact />} />
          <Route path="/complete" element={<LazyComplete />} />
          <Route path="/lp" element={<LazyLp />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
